
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: #F6F6F5;

  padding: 40px;
  border-radius: 16px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  @keyframes slideUp {
    from {
      max-height: 200px;
    }
    to {
      max-height: 0;
    }
  }

  @keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 200px;
    }
  }

  &.active {
    animation: slideDown 0.3s forwards;
    max-height: 200px;
    display: flex;
  }

  &.inactive {
    animation: slideUp 0.3s forwards;
    max-height: 0;
    display: none;
  }

  img {
    max-width: 100%;
    max-height: 160px;
  }

  @media ${device.tablet}{
    @keyframes slideUp {
      from {
        max-height: 400px;
      }
      to {
        max-height: 0;
      }
    }

    @keyframes slideDown {
      from {
        max-height: 0;
      }
      to {
        max-height: 400px;
      }
    }

    &.active {
      max-height: 400px;
    }

    &.inactive {
      max-height: 0;
    }

    img {
      max-height: 360px;
    }

  }

  @media (min-width: 1024px){
    display: none;
    &.active{
      display: none;
    }
  }

`
