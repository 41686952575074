import React, { FC } from 'react'
import * as S from './style'

interface ICardAccordionImageMobile {
  isActive: boolean;
  data: IDataAccordionImage;
}

const CardAccordionImageMobile: FC<ICardAccordionImageMobile> = ({ isActive = false, data }: ICardAccordionImageMobile) => {
  return (
    <S.Section className={`${isActive ? 'active' : 'inactive'}`}>
      <img
        src={data?.image}
        alt={data?.alt}
      />
    </S.Section>
  )
}

export default CardAccordionImageMobile
