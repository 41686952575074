import styled from 'styled-components'

export const Section = styled.div`
  border-bottom: 1px solid #e6e4e4;
  margin: 24px 0;
  width: 100%;
  position: relative;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Tab = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    color: #625A59;
    margin: 0;
  }

  &:hover {
    p {
      color: #1E1A19;
      font-weight: 600;
    }
  }
  &.active {
    p {
      color: #1E1A19;
      font-weight: 600;
    }
  }
`

export const Bar = styled.div`
  width: 50%;
  height: 2px;
  background-color: #EA7100;
  display: block;
  border-radius: 100px;

  position: absolute;
  transition: left 0.3s;
  left: 0;
  bottom: -1px;

  &.active {
    left: calc(50%);
  }
`
