import React, { FC } from "react"
import * as S from './style'

const TheInterBazar: FC = () => {
  return (
    <S.Section id='bazar-connect'>
      <S.Container>
        <p>
          O Bazar do Inter conecta pessoas interessadas em negociar produtos usados. Nossa plataforma permite que os usuários anunciem, busquem e compartilhem informações de maneira segura e prática, tornando a experiência de compra e venda mais fácil e eficiente.
        </p>
      </S.Container>
    </S.Section>
  )
}
export default TheInterBazar
