import { device } from 'src/styles/breakpoints'
import { grayscale, laranja, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: ${laranja.terra};

  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/heroimage_web_360/image.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media ${device.tablet}{
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/heroimage_web_768_1x/image.webp');
  }  

  @media (min-width: 1024px){
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/heroimage_web_1024_1x/image.webp');
  } 

  @media ${device.desktopXXL}{
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/heroimage_web_1440_1x/image.webp');
  } 
`

export const Container = styled.div`
  max-width: calc(360px - 48px);
  padding: 40px 0;
  margin: auto;

  @media ${device.tablet}{
    max-width: calc(768px - 48px);
    padding: 56px 0;
  }

  @media (min-width: 1024px){
    max-width: calc(1024px - 64px);
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    max-width: calc(1440px - 312px);
    padding: 96px 0;
  }
`

export const ContentInfo = styled.div`
  h1 {
    font-weight: 500;
    font-size: 52px;
    line-height: 56px;
    letter-spacing: -0.02px;
    color: ${white}
  }

  .subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${white};
    margin: 0;
  }

  @media ${device.tablet}{
    width: 444px;

    h1 {
      font-size: 52px;
    }
  }

  @media (min-width: 1024px){
    width: 520px;

    h1 {
      font-size: 72px;
      letter-spacing: -2px;
      line-height: 100%;
    }

    p {
      font-size: 20px;
    }
  }

  @media ${device.desktopXXL}{
    width: 720px;

    h1 {
      font-size: 104px;
    }

    p {
      font-size: 24px;
    }
  }
`

export const BoxIcons = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  .line-first{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .line-second{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    color: ${grayscale[600]};
    padding: 10px 0;
    border-radius: 50px;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }  

  .insurance {
    width: 150px;
    background-color: #FBFF71;
  }

  .easy-fast {
    width: 127px;
    background-color: #F8B6E6;
  }

  .money-to-account {
    width: 297px;
    background-color: #66CCFB;
  }

  .iconcard {
    background-color: #3C3331;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 42px;
  }

  @media ${device.tablet}{
    p {
      font-size: 14px;
    }

    .insurance {
      width: 160px;
      height: 43px;
    }

    .easy-fast {
      width: 127px;
      height: 43px;
    }

    .money-to-account {
      width: 199px;
      height: 43px;
    }
  }

  @media (min-width: 1024px){
    p {
      font-size: 18px;
    }

    .insurance {
      width: 210px;
    }

    .easy-fast {
      width: 193px;
    }

    .money-to-account {
      width: 297px;
    }
  }

`

export const BoxButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: ${grayscale[600]};
    padding: 14px 24px;
    border-radius: 50px;
    text-align: center;
    border: 1px solid ${white};
    &:hover {
      opacity: 0.9;
    }

    &.color-orange {
      color: ${orange[500]};
      background-color: ${white};
    }
    &.color-white {
      color: ${white};
      background-color: transparent;
    }
  }

  @media ${device.tablet}{
    flex-direction: row;
  }
`
