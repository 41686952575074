import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: ${orange[500]};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 24px;

  @media ${device.tablet}{
    max-width: calc(768px - 48px);
    flex-direction: row;
    margin: auto;
    padding: 56px 0;
  }

  @media (min-width: 1024px){
    max-width: calc(1024px - 64px);
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    max-width: calc(1440px - 330px);
    padding: 96px 0;
    gap: 160px;
  }
`

export const ContentTitle = styled.div`
  h2 {
    color: ${white};
    margin: 0;
    font-weight: 500;
    font-size: 28px;
    line-height: normal;
    letter-spacing: 0px;
  }

  @media ${device.tablet}{
    flex-basis: 50%;
  }

  @media (min-width: 1024px){
    h2{
      font-size: 40px;
      line-height: 100%;
    }
  }

  @media ${device.desktopXXL}{
    flex-basis: 47%;
  }
`

export const ContentDesc = styled.div`
  p {
    color: ${white};
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
  }

  @media ${device.tablet}{
    flex-basis: 50%;
  }

  @media (min-width: 1024px){
    p{
      font-size: 18px;
    }
  }

  @media ${device.desktopXXL}{
    flex-basis: 53%;
  }
`
