import { device } from 'src/styles/breakpoints'
import { white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: ${white};
`

export const Container = styled.div`
  max-width: calc(360px - 30px);
  padding: 40px 0;
  margin: auto;

  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0px;
    text-align: center;
  }

  @media ${device.tablet}{
    max-width: calc(768px - 48px);
    padding: 56px 0;
  }

  @media (min-width: 1024px){
    max-width: calc(1024px - 64px);
    padding: 64px 0;

    h2{
      font-size: 40px;
    }
  }

  @media ${device.desktopXXL}{
    max-width: calc(1440px - 156px - 156px);
    padding: 96px 0;

    h2{
      font-size: 48px;
    }
  }
`

export const ContentAccordion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1024px){
    flex-basis: 372px;
    gap: 16px;
  }

`

export const CardAccordion = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`

export const BoxAccordion = styled.div`
  @media (min-width: 1024px){
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
`

export const BoxImage = styled.div`
  display: none;

  @media (min-width: 1024px){
    flex-basis: calc(100% - 372px - 24px);
    background-color: #F6F6F5;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 360px;
    }
  }

`
