
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: #F6F6F5;

  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  
  gap: 16px;

  &.active{
    background-color: #EFEEEE
  }

  .my-component {
    display: none;
  }
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

export const Number = styled.span`
  font-size: 29px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.02px;
  color: ${grayscale[500]};

  &.inactive {
    display: none;
  }

`

export const Title = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    color: ${grayscale[500]};
    margin: 0;
  }

  &.inactive{
    p{
      font-weight: 400;
      color: ${grayscale[400]};
    }
    &:hover {
      p{
        font-weight: 600;
        color: ${grayscale[500]};
      }
    }
  }

`

export const Description = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  @keyframes slideUp {
    from {
      max-height: 100px;
    }
    to {
      max-height: 0;
    }
  }

  @keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 100px;
    }
  }

  p, span {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: ${grayscale[500]};
    margin: 0 0 8px 0;
    display: block;
  }

  a {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    color: ${orange[500]};
  }

  &.active {
    animation: slideDown 0.3s forwards;
    max-height: 100px;
    display: block;
  }

  &.inactive {
    animation: slideUp 0.3s forwards;
    max-height: 0;
    display: none;
  }
`
