import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  overflow: hidden;
  background-color: ${grayscale[100]};
`

export const Container = styled.div`
  width: 100%;
  padding: 40px 24px;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${device.tablet}{
    flex-direction: row;
    align-items: center;
    padding: 64px 0;
    max-width: calc(768px - 64px);
  }

  @media (min-width: 1024px){
    max-width: calc(1024px - 64px);
    gap: 100px;
  }

  @media ${device.desktopXXL}{
    max-width: calc(1440px - 330px);
    gap: 150px;
    padding: 96px 0;
  }

`

export const ContentImage = styled.div`
  img { 
    width: 100%;
  }

  @media ${device.tablet}{
    flex-basis: 50%;
  }

  @media (min-width: 1024px){
    text-align: center;
    img {
      width: 384px;
    }
  }

  @media ${device.desktopXXL}{
    flex-basis: 45%;

    img {
      width: 400px;
    }
  }
`

export const ContentInfo = styled.div`
  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[500]};
    margin-bottom: 32px;

    &.desktop{
      display: none;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #625A59
  }
  a {
    display: block;
    color: ${white};
    background-color: ${orange[500]};
    text-align: center;
    padding: 14px 0;
    width: 100%;
    border-radius: 100px;

    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;

    &:hover{
      opacity: 0.9;
    }
  }

  @media ${device.tablet}{
    flex-basis: 50%;

    a {
      max-width: 250px;
    }
  }

  @media (min-width: 1024px){
    h2{
      font-size: 40px;
    }
    p{
      font-size: 18px;
    }
    a {
      margin-top: 40px;
    }
  }

  @media ${device.desktopXXL}{
    flex-basis: 55%;
    
    h2 {
      font-size: 48px;
      &.mobile {
        display: none;
      }
      &.desktop{
        display: block;
      }
    }
  }
`
