import React, { FC } from "react"
import * as S from './style'

const TransformProductsExtraIncome: FC = () => {
  return (
    <S.Section id='bazar-extra-income'>
      <S.Container>
        <S.ContentTitle>
          <h2>
            Transforme produtos que você não usa em uma fonte de renda extra
          </h2>
        </S.ContentTitle>
        <S.ContentDesc>
          <p>
            Ao revender itens que estão parados em casa, você libera espaço e ainda ganha dinheiro de forma prática e rápida. Com o Bazar você alcança um público diversificado, interessado em adquirir produtos a preços acessíveis e de qualidade.
          </p>
        </S.ContentDesc>
      </S.Container>
    </S.Section>
  )
}
export default TransformProductsExtraIncome
