import React, { FC } from 'react'
import * as S from './style'

interface ICardDiscoverBazar {
  isActive: boolean;
  tabActive: string;
  data: IDataAccordion;
  onClick: () => void;
}

const CardDiscoverBazar: FC<ICardDiscoverBazar> = ({ isActive = false, tabActive = 'Sell', data, onClick }: ICardDiscoverBazar) => {
  return (
    <S.Section className={`${isActive ? 'active' : 'inactive'}`}>
      <S.Number className={`${tabActive === 'Buy' ? 'inactive' : 'active'}`}>
        {data.number}
      </S.Number>

      <S.ContentInfo onClick={onClick}>
        <S.Title
          className={`${isActive ? 'active' : 'inactive'}`}
          dangerouslySetInnerHTML={{ __html: data?.title }}
        />

        <S.Description
          className={`${isActive ? 'active' : 'inactive'}`}
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
      </S.ContentInfo>
    </S.Section>
  )
}

export default CardDiscoverBazar
