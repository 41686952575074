import { device } from 'src/styles/breakpoints'
import { grayscale, laranja } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: ${laranja.terra};
`

export const Container = styled.div`
  max-width: calc(360px - 48px);
  margin: auto;
  padding: 40px 0;

  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${grayscale[100]};
    margin: 0;
    text-align: center;
  }

  @media ${device.tablet}{
    padding: 56px 0;
    max-width: calc(768px - 48px);

    p {
      font-size: 18px;
    }
  }

  @media (min-width: 1024px){
    padding: 64px 0;
    max-width: calc(1024px - 64px);

    p {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.02px;
    }
  }

  @media ${device.desktopXXL}{
    max-width: calc(1440 - 312px);
    padding: 96px 0;
    
    p {
      font-size: 29px;
      line-height: 36px;
    }
  }
`
