import React, { FC } from "react"
import * as S from './style'

enum ETab {
  Sell = 'Sell',
  Buy = 'Buy'
}

interface ITab {
  active: ETab;
  onActive: (value: ETab) => void;
}

const Tab: FC<ITab> = ({ active, onActive }: ITab) => {
  return (
    <S.Section>
      <S.Content>
        <S.Tab
          className={`${active === 'Sell' ? 'active' : 'inactive'}`}
          onClick={() => onActive(ETab.Sell)}
        >
          <p>Quero vender</p>
        </S.Tab>
        <S.Tab
          className={`${active === 'Buy' ? 'active' : 'inactive'}`}
          onClick={() => onActive(ETab.Buy)}
        >
          <p>Quero comprar</p>
        </S.Tab>
      </S.Content>
      <S.Bar className={`${active === 'Buy' ? 'active' : 'inactive'}`} />
    </S.Section>
  )
}

export default Tab
