import React, { FC, useState } from "react"
import * as S from './style'
import CardDiscoverBazar from "../../components/CardDiscoverBazar/_index"
import CardAccordionImageMobile from "../../components/CardAccordionImageMobile/_index"
import Tab from "../../components/Tab/_index"

const dataWantToSell: IDataAccordion[] = [
  {
    number: '1',
    title: "<p>Acesse sua conta no Internet Banking</p>",
    description: `
      <p>Acesse a conta através do navegador para iniciar o cadastro;</p>
      <a href='https://contadigital.inter.co/' title='Descubra como usar o Bazar' target='_blank' rel='noopener noreferrer'>
        Acessar
      </a>`,
  },
  {
    number: '2',
    title: "<p>Selecione <strong>Vender no Bazar</strong></p>",
    description: `<p>Na aba <strong>Inter Shop</strong>, acesse a opção vender no Bazar;</p>`,
  },
  {
    number: '3',
    title: "<p>Crie seu anúncio</p>",
    description: `<p>Clique em Criar anúncio para começar a anunciar;</p>`,
  },
  {
    number: '4',
    title: "<p>Preencha os dados</p>",
    description: `<p>Preencha todos os dados do seu anúncio e finalize o cadastro;</p>`,
  },
  {
    number: '5',
    title: "<p>Compartilhe no Forum!</p>",
    description: `
      <p>Após a aprovação, compartilhe a sua oferta no Forum;</p>
      <a href='https://blog.inter.co/forum-o-que-e-e-como-funciona-da-rede-social-do-inter/' title='Conheça o Forum' target='_blank' rel='noopener noreferrer'>
        Conheça o Forum
      </a>
    `,
  },
]

const dataImageWantToSell: IDataAccordionImage[] = [
  {
    alt: 'Tela de login do Inter',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar_vender_foto01/image.webp',
  },
  {
    alt: 'Tela de dashboard do Inter Bazar',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar-vender_foto02/image.webp',
  },
  {
    alt: 'Tela de dashboard do Inter Bazar - criar anúncio',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar_vender_foto03/image.webp',
  },
  {
    alt: 'Tela da dashboard do Inter Bazar - formulario',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar_vender_foto04/image.webp',
  },
  {
    alt: 'Tela da dashboard do Inter Bazar - Forum de vendas',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar_vender_foto05/image.webp',
  },
]

const dataWantToBuy: IDataAccordion[] = [
  {
    number: '1',
    title: "<p>Bazar no Inter Shop</p>",
    description: `<p>Encontre os produtos na Loja do Bazar no Inter Shop;</p>`,
  },
  {
    number: '2',
    title: "<p>Forum do Inter</p>",
    description: `<p>Os vendedores também podem compartilhar seus desapegos no Feed do Forum</p>`,
  },

]

const dataImageWantToBuy: IDataAccordionImage[] = [
  {
    alt: 'Tela de login do Inter',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar_comprar_foto01/image.webp',
  },
  {
    alt: 'Tela de dashboard do Inter Bazar',
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/bazar_comprar_foto02/image.webp',
  },
]

enum ETab {
  Sell = 'Sell',
  Buy = 'Buy'
}

const BazarDiscover: FC = () => {
  const [ openItem, setOpenItem ] = useState<string>(dataWantToSell[0]?.number)
  const [ selectTab, setSelectTab ] = useState<ETab>(ETab.Sell)

  const handleSelectTab = (select: ETab) => {
    setOpenItem(dataWantToSell[0]?.number)
    setSelectTab(select)
  }

  const handleSelectAccordion = (number: string) => {
    if (openItem !== number) {
      setOpenItem(number)
    }
  }

  return (
    <S.Section id='bazarDiscover'>
      <S.Container>
        <h2>Descubra como usar o Bazar</h2>

        <Tab active={selectTab} onActive={handleSelectTab} />

        <div style={{ display: `${selectTab === ETab.Sell ? 'block' : 'none'}` }}>
          <S.BoxAccordion>

            <S.ContentAccordion>
              {dataWantToSell?.map((item: IDataAccordion, index: number) => (
                <S.CardAccordion key={item?.number}>
                  <CardAccordionImageMobile
                    data={dataImageWantToSell[index]}
                    isActive={openItem === item.number}
                  />
                  <CardDiscoverBazar
                    data={item}
                    tabActive={selectTab}
                    isActive={openItem === item.number}
                    onClick={() => handleSelectAccordion(item.number)}
                  />
                </S.CardAccordion>
              ))}
            </S.ContentAccordion>

            <S.BoxImage>
              <img
                src={dataImageWantToSell[parseInt(openItem as string, 10) - 1]?.image}
                alt={dataImageWantToSell[parseInt(openItem as string, 10) - 1]?.alt}
              />
            </S.BoxImage>

          </S.BoxAccordion>
        </div>

        <div style={{ display: `${selectTab === ETab.Buy ? 'block' : 'none'}` }}>
          <S.BoxAccordion>

            <S.ContentAccordion>
              {dataWantToBuy?.map((item: IDataAccordion, index: number) => (
                <S.CardAccordion key={item?.number}>
                  <CardAccordionImageMobile
                    data={dataImageWantToBuy[index]}
                    isActive={openItem === item.number}
                  />
                  <CardDiscoverBazar
                    data={item}
                    tabActive={selectTab}
                    isActive={openItem === item.number}
                    onClick={() => handleSelectAccordion(item.number)}
                  />
                </S.CardAccordion>
              ))}
            </S.ContentAccordion>

            <S.BoxImage>
              <img
                src={dataImageWantToBuy[parseInt(openItem as string, 10) - 1]?.image}
                alt={dataImageWantToBuy[parseInt(openItem as string, 10) - 1]?.alt}
              />
            </S.BoxImage>

          </S.BoxAccordion>
        </div>

      </S.Container>
    </S.Section>
  )
}

export default BazarDiscover
