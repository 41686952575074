import React, { FC } from "react"
import * as S from './style'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

const SafeTransitions: FC = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const buttonLink = 'https://contadigital.inter.co/'

  return (
    <S.Section
      id='bazar-safe-transitions'
    >
      <S.Container>
        <S.ContentImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/bazar-dobra6-web/image.webp'
            alt='Um homem utilizando o celular'
          />
        </S.ContentImage>
        <S.ContentInfo>
          <h2>Transações com segurança</h2>
          <p>
            Com suporte oferecido pelo Inter, você pode vender os seus produtos usados contribuindo para um consumo mais consciente e sustentável.
          </p>
          <p>
            Aproveite a oportunidade de fazer aquela graninha extra enquanto dá uma nova vida aos seus itens!
          </p>
          <a
            href={buttonLink}
            title='Começar a vender'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => sendDatalayerEvent({
              section: 'dobra_06',
              element_action: 'click button',
              element_name: 'Começar a vender',
              section_name: 'Seus anúncios no Forum, a rede social do Inter',
              redirect_url: buttonLink,
            })}
          >
            Começar a vender
          </a>
        </S.ContentInfo>
      </S.Container>
    </S.Section>
  )
}
export default SafeTransitions
