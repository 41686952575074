import React, { FC } from "react"
import IconArrow from '../../assets/images/arrow.svg'
import IconMoney from '../../assets/images/money.svg'
import IconSmarthphone from '../../assets/images/smarthphone.svg'
import * as S from './style'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

const Hero: FC = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Section id='bazar-hero'>
      <S.Container>
        <S.ContentInfo>
          <h1>Conheça o Bazar do Inter</h1>
          <p className='subtitle'>Venda seus produtos com toda a segurança e praticidade que você já conhece.</p>
          <S.BoxIcons>
            <div className='line-first'>
              <div className='iconarrow iconcard'>
                <img src={IconArrow} alt='Icon Money' />
              </div>
              <p className='insurance'>Transação Segura</p>
              <div className='iconmoney iconcard'>
                <img src={IconMoney} alt='Icon Money' />
              </div>
              <p className='easy-fast'>Fácil e rápido</p>
            </div>
            <div className='line-second'>
              <div className='iconsmarthphone iconcard'>
                <img src={IconSmarthphone} alt='Icon Smarthphone' />
              </div>
              <p className='money-to-account'>Dinheiro direto na conta</p>
            </div>
          </S.BoxIcons>
          <S.BoxButton>
            <a
              href='https://contadigital.inter.co/'
              className='color-orange'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'Começar a vender',
                  section_name: 'Conheça o Bazar do Inter',
                  redirect_url: 'https://contadigital.inter.co/',
                })
              }}
            >
              Começar a vender
            </a>
            <a
              href='#bazarDiscover'
              className='color-white'
            >
              Conheça as vantagens
            </a>
          </S.BoxButton>
        </S.ContentInfo>
      </S.Container>
    </S.Section>
  )
}
export default Hero
