import React, { FC } from "react"
import * as S from './style'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

const YourAdsOnTheForum: FC = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const buttonLink = 'https://blog.inter.co/forum-o-que-e-e-como-funciona-da-rede-social-do-inter/'

  return (
    <S.Section
      id='bazar-your-ad-on-the-forum'
    >
      <S.Container>
        <S.ContentImage>
          <img
            src='https://central-imagens.bancointer.com.br/images-without-small-versions/bazar-passo8/image.webp'
            alt='Imagem de um celular'
          />
        </S.ContentImage>
        <S.ContentInfo>
          <h2 className='mobile'>Seus anúncios no Forum</h2>
          <h2 className='desktop'>Seus anúncios no Forum, a rede social do Inter</h2>
          <p>
            Descubra um espaço exclusivo dentro do nosso Super App, onde você pode trocar ideias, aprender e compartilhar experiências com uma comunidade engajada.
          </p>
          <p>
            Além disso, aproveite a oportunidade de publicar seus anúncios de forma simples e eficaz, aumentando suas chances de venda e alcançando mais potenciais compradores.
          </p>
          <a
            href={buttonLink}
            title='Saiba mais'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => sendDatalayerEvent({
              section: 'dobra_07',
              element_action: 'click button',
              element_name: 'Saiba mais',
              section_name: 'Seus anúncios no Forum, a rede social do Inter',
              redirect_url: buttonLink,
            })}
          >
            Saiba mais
          </a>
        </S.ContentInfo>
      </S.Container>
    </S.Section>
  )
}

export default YourAdsOnTheForum
