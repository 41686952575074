import React, { FC } from "react"
import { Wrapper } from './style'
import pageContext from './pageContext.json'

import Layout from 'src/layouts/BaseLayout'

import Hero from "./sections/Hero/_index"
import TheInterBazar from "./sections/TheInterBazar/_index"
import BazarConnectProduct from "./sections/BazarConnectProduct/_index"
import BazarDiscover from "./sections/BazarDiscover/_index"
import TransformProductsExtraIncome from "./sections/TransformProductsExtraIncome/_index"
import SafeTransitions from "./sections/SafeTransitions/_index"
import YourAdsOnTheForum from "./sections/YourAdsOnTheForum/_index"
import Faq from './sections/faq/_index'

const Bazar: FC = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <TheInterBazar />
        <BazarConnectProduct />
        <BazarDiscover />
        <TransformProductsExtraIncome />
        <SafeTransitions />
        <YourAdsOnTheForum />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default Bazar
