import React from 'react'
import FAQ from 'src/components/Faq/index'

import pageContext from '../../pageContext.json'
const Faq = () => {
  return (
    <section className='white'>
      <div className='container py-5'>
        <div className='row'>
          <div className='mb-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 fw-400'>
              Perguntas frequentes
            </h2>
          </div>
          <FAQ
            answerData={pageContext?.structuredData?.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
          />
        </div>
      </div>
    </section>
  )
}

export default Faq
