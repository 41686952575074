import React, { FC } from "react"
import * as S from './style'
import useDataLayer from "src/hooks/useDataLayer/dataLayerBody"

import IconPadlock from '../../assets/images/padlock.svg'
import IconDesktop from '../../assets/images/desktop.svg'
import IconWallet from '../../assets/images/wallet.svg'

interface ICard {
  icon: '*.svg';
  title: string;
  description: string;
}

const DataCard: ICard[] = [
  {
    icon: IconPadlock,
    title: "Confiabilidade",
    description: "Transações seguras com a confiança do Inter.",
  },
  {
    icon: IconDesktop,
    title: "Facilidade",
    description: "Interface intuitiva que facilita a venda.",
  },
  {
    icon: IconWallet,
    title: "Conveniência",
    description: "Receba o pagamento direto na sua conta Inter.",
  },
]

const BazarConnectProduct: FC = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const urlButton = 'https://contadigital.inter.co/'

  return (
    <S.Section
      id='BazarConnectProduct'
    >
      <S.Container>
        <h2>O Bazar conecta você com o seu produto de desejo</h2>
        <p className='subtitle'>Aproveite a conveniência de gerenciar todas as suas vendas em um único lugar. Expanda seus horizontes de venda com o Bazar!</p>

        <S.ContentCards>
          {DataCard.map((item: ICard) => (
            <S.Card key={item.title}>
              <S.CardImage>
                <img src={item?.icon} alt='icon' />
              </S.CardImage>
              <S.CardTitle>{item?.title}</S.CardTitle>
              <S.CardDescription>{item?.description}</S.CardDescription>
            </S.Card>
          ))}

        </S.ContentCards>

        <S.Button
          href={urlButton}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_02',
              element_action: 'click button',
              element_name: 'Começar a vender',
              section_name: 'O Bazar conecta você com o seu produto de desejo',
              redirect_url: urlButton,
            })
          }}
        >
          Começar a vender
        </S.Button>
      </S.Container>
    </S.Section>
  )
}
export default BazarConnectProduct
