import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  background-color: #F6F6F5;
  padding: 40px 0;

  @media ${device.tablet}{
    padding: 56px 0;
  }

  @media (min-width: 1024px){
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }
`

export const Container = styled.div`
  max-width: calc(360px - 30px);
  margin: auto;
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  h2 {
    font-weight: 500;
    font-size: 28px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[500]};
    margin: 0;
  }

  p.subtitle{
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[400]};
    margin: 0;
  }

  @media ${device.tablet}{
    max-width: calc(768px - 48px);

    p.subtitle{
      font-size: 16px;
      margin: 0 8px;
    }
  }

  @media (min-width: 1024px){
    max-width: calc(1024px - 64px);
    gap: 32px;

    h2{
      font-size: 40px;
    }
    p.subtitle{
      font-size: 18px;
      width: 740px;
    }
  }

  @media ${device.desktopXXL}{
    max-width: calc(1440px - 312px);
    gap: 24px;

    h2{
      font-size: 48px;
    }
  }
`

export const ContentCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media ${device.tablet}{
    flex-direction: row;
  }

  @media ${device.desktopXXL}{
    margin: 16px 0;
    width: 865px;
  }
`

export const Card = styled.div`
  background-color: ${white};
  padding: 24px 0;
  border-radius: 16px;
  
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 16px;
`

export const CardImage = styled.div`
  background-color: ${grayscale[100]};
  height: 64px;
  width: 64px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }
`

export const CardTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01px;
  color: ${grayscale[500]};
  margin: 0;
`

export const CardDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: #1E1A19;
  width: 210px;
  margin: 0;
`

export const Button = styled.a`
  display: block;
  color: ${white};
  background-color: ${orange[500]};
  text-align: center;
  padding: 14px 0;
  width: 100%;
  border-radius: 100px;

  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;

  &:hover{
    opacity: 0.9;
  }

  @media ${device.tablet}{
    width: 240px;
  }
`
